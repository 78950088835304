import React, { useState } from 'react';
import { AppBar, Toolbar, Box,Typography, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Backdrop, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import buyscan from './assets/Sadia/buy-scan-win.png';
import car from './assets/Sadia/car.png';
import chips from './assets/Sadia/chips.png';
import playstation from './assets/Sadia/playstation.png';
import sadialogo from './assets/Sadia/sadia-logo.png';
import text from './assets/Sadia/text.png';

import logo from './assets/logo.png';

const App = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false); // New state for the success dialog
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    nationality: '',
    city: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      const { name, email, nationality, city } = formData;
      if (name && email && nationality && city) {
        setOpen(true);
      } else {
        alert('Please fill out all fields.');
      }
    }
  };

  const handleClose = async (confirm) => {
    if (confirm) {
      setOpen(false);
      setLoading(true);
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('nationality', formData.nationality);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('image', selectedImage);

        const response = await fetch('https://sadia.symloop.com/api/information', {
          method: 'POST',
          body: formDataToSend,
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          toast.success('Information uploaded successfully!');

          setFormData({
            name: '',
            email: '',
            nationality: '',
            city: '',
          });
          setSelectedImage(null);

          // Show the success dialog
          setSuccessDialogOpen(true);
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.message || 'Something went wrong'}`);
        }
      } catch (error) {
        console.error('Error uploading the information', error);
        toast.error(`Error: ${error.message}`);
      } finally {
        setLoading(false); // Hide the loading indicator
      }
    } else {
      setOpen(false);
    }
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', margin: 0, padding: 0 }}>
   
   
   
     <Box 
        sx={{ 
          position: 'relative', 
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          margin: 0,
          padding: 0
        }}
      >
        
      </Box>
      
      <Box 
        sx={{ 
          flexGrow: 1, 
          display: 'flex',
          flexDirection: 'column',
          margin: 0,
          padding: 0,
          '@media (min-width: 600px)': {
            flexDirection: 'row',
          }
        }}
      >
        <Box 
          sx={{ 
            flex: 1, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%',
            padding: 2,
            position: 'relative'
          }}
        >
          <img src={buyscan} alt="buyscan" style={{ position: 'absolute', top: '5%', left: '40%', height: "22%" }} />
          <img src={car} alt="car" style={{ position: 'absolute', top: '55%', left: '100%', height: "45%",zIndex: "-1" }} />
          <img src={chips} alt="chips" style={{ position: 'absolute', bottom: 0, left: "30%",height: "60%",top: '35%', zIndex:'-2' }} />
          <img src={playstation} alt="playstation" style={{ position: 'absolute', top: '55%', right: 0,height:'32%',left:"20%" }} />
          <img src={sadialogo} alt="sadialogo" style={{ position: 'absolute', top: '0%', left: 0,height:'40%'  }} />


         
        </Box>

        <Box 
          sx={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center',
            padding: 2,
            '@media (min-width: 600px)': {
              padding: 1,
            },
            '@media (min-width: 900px)': {
              padding: 3,
            }
          }}
        >
          {/* Hidden file input */}
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="raised-button-file">
           
          </label>
          {/* Right container with text fields */}
          <TextField 
            variant="outlined" 
            placeholder="NAME/الإسم" 
            name="name"
            value={formData.name}
            onChange={handleChange}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
            }}
            sx={{ 
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              borderRadius: 5,
              marginBottom: 2, 
              width: '60%', 
              border: '2px white', 
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '& input::placeholder': {
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center'
                },
              }
            }} 
          />
          <TextField 
            variant="outlined" 
            placeholder="EMAIL/البريد الإلكتروني" 
            name="email"
            value={formData.email}
            onChange={handleChange}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
            }}
            sx={{ 
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              borderRadius: 5,
              marginBottom: 2, 
              width: '60%', 
              border: '2px white', 
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '& input::placeholder': {
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center'
                },
              }
            }} 
          />
          <TextField 
            variant="outlined" 
            placeholder="PHONE NUMBER/رقم الهاتف" 
            name="nationality"
            value={formData.nationality}
            onChange={handleChange}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
            }}
            sx={{ 
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              borderRadius: 5,
              marginBottom: 2, 
              width: '60%', 
              border: '2px white', 
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '& input::placeholder': {
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center'
                },
              }
            }} 
          />
          <TextField 
            variant="outlined" 
            placeholder="CITY/المدينة" 
            name="city"
            value={formData.city}
            onChange={handleChange}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'bold', color: 'white' },
            }}
            sx={{ 
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              borderRadius: 5,
              width: '60%', 
              border: '2px white', 
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: 'white', // Ensure placeholder text is white
                  fontWeight: 'bold',
                  textAlign: 'center',
                },
              }
            }} 
          />
          <Box 
            sx={{ 
              backgroundColor: 'white', 
              marginTop: 2,
              color: 'black', 
              fontWeight: 'bold', 
              textAlign: 'center', 
              borderRadius: 5, 
              width: '60%', 

              marginBottom: 2,
              padding: 1,
              fontSize: '1.8rem', 
              cursor: 'pointer' 
            }}
            onClick={() => document.getElementById('raised-button-file').click()}
          >
            Upload Receipt
          </Box>
         
        </Box>
      </Box>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Upload Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to upload the receipt?
          </DialogContentText>
          {selectedImage && <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={{ width: '100%' }} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} sx={{ fontFamily: 'MyFont', fontWeight: 'bold' }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={successDialogOpen}
        onClose={handleSuccessDialogClose}
      >
        <DialogTitle>Thank You for Your Submission!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We have received your receipt and information. Your participation is currently under review to ensure it meets our terms and conditions. You will receive a confirmation shortly. Stay Tuned!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      
      <Box
  sx={{
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingBottom:'0px',
    width: '100%',
   
    background: 'linear-gradient(to top, #581014, #581014, transparent)', // Add additional stops
    zIndex: 10,
    display: 'flex', 
    flexDirection:'column',
    justifyContent: 'center', // Optional: Center horizontally
    alignItems: 'center', // Optional: Center vertically
  }}
>
  <img
    src={text} // Replace with your image path
    alt="Your Image"
    style={{
      height: '65px', // Adjust size as needed
      objectFit: 'contain', // Optional: Ensure the image fits within the box
    }}
  />
  
   <Typography sx={{ color: 'white', fontWeight: 'bold',paddingTop: '5px' ,paddingBottom:'0px !important'}}>
   1st December Till 31 jan
  </Typography>
</Box>




      <ToastContainer position="bottom-center" />
    </div>
  );
};

export default App;
